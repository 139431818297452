<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(handleSubmitData)">
        <div class="form-wrapper" style="width: auto; margin-top: 15px">
          <div class="flex-container">
            <div class="flex-row">
              <div class="key">
                <p class="input-label">
                  Menu Type
                  <span class="field-required">*</span>
                </p>
              </div>
              <div class="value input-expanded">
                <b-field class="value input-expanded">
                  <b-select v-model="data.parent_id">
                    <option value="0">Menu</option>
                    <option value="1">Sub Menu</option>
                  </b-select>
                </b-field>
              </div>
            </div>

            <div class="flex-row">
              <div class="key">
                <p class="input-label">
                  Menu Name
                  <span class="field-required">*</span>
                </p>
              </div>
              <div class="value input-expanded">
                <ValidationProvider
                  name="name"
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <b-input v-model="data.name" expanded></b-input>
                  <p class="notif has-text-danger required">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </div>
            </div>

            <div class="flex-row" v-if="data.parent_id == 1">
              <div class="key">
                <p class="input-label">
                  URL
                  <span class="field-required">*</span>
                </p>
              </div>
              <div class="value input-expanded">
                <ValidationProvider
                  name="url"
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <b-input
                    v-model="data.url"
                    expanded
                    placeholder="ex: access"
                  ></b-input>
                  <p class="notif has-text-danger required">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </div>
            </div>

            <b-button
              native-type="submit"
              type="is-hcc"
              expanded
              style="font-weight: bold"
            >
              {{ editData == null ? 'Add' : 'Edit' }}
            </b-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    editData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      data: {
        id: null,
        name: null,
        parent_id: 0,
        url: null,
      },
    }
  },
  mounted() {
    if (this.editData != null) {
      this.data.id = this.editData.id
      this.data.name = this.editData.name
      this.data.parent_id = this.editData.parent_id
      this.data.url = this.editData.url
    }
  },
  methods: {
    ...mapActions({
      createAccess: 'newAccess/createAccess',
      updateAccess: 'newAccess/updateAccess',
    }),
    async handleSubmitData() {
      let data = {
        id: this.data.id,
        name: this.data.name,
        parent_id: this.data.parent_id,
        url: this.data.url,
      }
      this.isLoading = true
      try {
        if (this.editData == null) {
          await this.createAccess(data)
          this.success('Create Access Success')
        } else {
          await this.updateAccess(data)
          this.success('Update Access Success')
        }
        this.data = {
          id: null,
          name: null,
          parent_id: null,
          url: null,
        }
        this.isLoading = false
        this.$emit('close-modal', { reload: true })
      } catch (err) {
        this.danger(err.response.data.message)
        this.isLoading = false
      }
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.input-expanded .control {
  width: 100%;
}

.flex-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
}

.flex-row {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: left;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.key p {
  text-align: left;
  min-width: 200px;
  font-weight: bold;
  color: #000000;
}

.value {
  display: flex;
  flex: 1;
}

.notif {
  text-align: left;
  font-size: 12px;
}
</style>
