<template>
  <div class="main index" v-if="!isLoading">
    <!-- For title -->
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="is-size-3"><b>Settings</b></h1>
      </div>
      <div class="column is-10">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <h3 class="is-size-6">Setting</h3>
            </li>
            <li>
              <h3 class="is-size-6 breadcrumb-item">Access</h3>
            </li>
          </ul>
        </nav>
      </div>
      <div class="column is-2">
        <!-- <b-field grouped position="is-right">
          <b-button
            type="is-hcc"
            title="Create Access"
            @click="isModalOpen = true"
          >
            Create
          </b-button>
        </b-field> -->
      </div>
    </div>

    <!-- For table -->
    <div class="columns is-mobile">
      <div class="column is-12">
        <div class="card card-table">
          <AccessTable ref="table" v-on:edit-access="editAccess" />
        </div>
      </div>
    </div>
    <!-- For Create -->
    <NewModal
      v-if="isModalOpen"
      :is-modal-open="isModalOpen"
      :title-modal="editData == null ? 'Add Access' : 'Edit Access'"
      :on-close-modal-callback="closeModal"
    >
      <template v-slot:content>
        <FormAccess v-on:close-modal="closeModal" :edit-data="editData" />
      </template>
    </NewModal>
  </div>
</template>

<script>
import AccessTable from '@/components/V2/Access/AccessTable'
import FormAccess from '@/views/v2/access/FormAccess'
import NewModal from '@/components/V2/NewModal'

export default {
  name: 'index-access',
  components: {
    AccessTable,
    NewModal,
    FormAccess,
  },
  data() {
    return {
      activeTab: 0,
      isLoading: false,
      isModalOpen: false,
      editData: null,
    }
  },
  methods: {
    closeModal(event) {
      if (event.reload != undefined) {
        if (this.$refs.table !== undefined) {
          this.$refs.table.reload()
        }
      }
      this.isModalOpen = false
      this.editData = null
    },
    editAccess(event) {
      this.editData = event
      this.isModalOpen = true
    },
  },
}
</script>
