<template>
  <div>
    <div class="columns is-mobile is-multiline">
      <span class="column is-4">
        <b-field>
          <b-input
            v-model="search"
            placeholder="Search..."
            type="search"
            icon="search"
          ></b-input>
        </b-field>
      </span>
      <span class="column is-8">
        <b-field grouped position="is-right">
          <b-select v-model="perPage" @input="onPerPageChange">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </b-select>
        </b-field>
      </span>
    </div>

    <b-table
      :data="access && access.data ? access.data : []"
      :current-page.sync="currentPage"
      paginated
      backend-pagination
      :total="access && access.total ? access.total : 0"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <b-table-column
        field="index"
        width="20"
        numeric
        label="No"
        v-slot="props"
      >
        {{ props.index + access.from }}
      </b-table-column>
      <b-table-column field="name" label="Name" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="url" label="URL" sortable v-slot="props">
        {{ props.row.url || '-' }}
      </b-table-column>
      <b-table-column field="action" centered label="Action" v-slot="props">
        <!-- <template>
          <b-button
            type="icon-button"
            icon-left="pencil-alt"
            @click="
              editAccess(
                props.row.id,
                props.row.name,
                props.row.parent_id,
                props.row.url
              )
            "
          ></b-button>
        </template> -->
      </b-table-column>
      <template slot="bottom-left">
        {{ access && access.from ? access.from : 0 }}
        -
        {{ access && access.to ? access.to : 0 }}
        from
        {{ access && access.total ? access.total : 0 }}
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="frown" size="is-large"></b-icon>
            </p>
            <p>Data not found.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  data() {
    const today = new Date()
    return {
      currentDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      ),
      isLoading: false,
      isLoadingTable: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      defaultSortOrder: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
      activeTab: 0,
      sortField: 'created_at',
      sortOrder: 'DESC',
      page: 1,
      search: null,
      link: '',
      message: {
        text: '',
        type: '',
      },
      isModalPreviewActive: false,
      isModalLinkActive: false,
    }
  },
  mounted() {
    this.fetchData(
      this.perPage,
      this.currentPage,
      this.sortField,
      this.sortOrder,
      this.search
    )
  },
  methods: {
    ...mapActions({
      fetchAccess: 'newAccess/fetchAccess',
      deleteAccessData: 'newAccess/deleteAccess',
    }),
    reload() {
      this.fetchData(
        this.perPage,
        this.currentPage,
        this.sortField,
        this.sortOrder,
        this.search
      )
    },
    // For search category
    searchAccess: debounce(function(value) {
      if (value) {
        this.search = value
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search
        )
      } else {
        this.search = null
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search
        )
      }
    }, 500),

    // For delete access
    async deleteAccess(id) {
      this.isLoading = true

      try {
        await this.deleteAccessData(id);

        this.success('Delete Access Success');
      } catch (err) {
        this.danger(err);
      }

      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search
      );

      this.isLoading = false
    },

    // For fetching data
    async fetchData(perPage, page, sortField, sortOrder, search) {
      let data = {
        perPage: perPage,
        page: page,
        sortField: sortField,
        sortOrder: sortOrder,
        search: search,
      }

      this.isLoadingTable = false
      try {
        await this.fetchAccess(data);
      } catch (err) {
        this.danger(err);
      }
      this.isLoadingTable = true
    },

    editAccess(id, name, parent_id, url) {
      this.$emit('edit-access', { id, name, parent_id, url });
    },

    // For Page Change
    onPageChange(page) {
      this.currentPage = page
      this.page = page
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search
      );
    },

    // For Sorting Data
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search
      );
    },

    // For per page change
    onPerPageChange(value) {
      this.perPage = value
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search
      );
    },

    // For success alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      });
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      });
    },
  },
  watch: {
    search: function(val) {
      this.searchAccess(val);
    },
  },
  computed: {
    ...mapGetters({
      access: 'newAccess/getNewAccess',
    }),
  },
}
</script>
<style scoped>
.modal-copy-link {
  width: 500px !important;
}
</style>
